@import "global";

.welcome {
  padding-left: 0;
  padding-right: 0;
  align-items: center;
  justify-content: center;
  background-color: #a77ef9;
  height: auto;
  overflow: hidden;
  //z-index: 2;

  &__svg {
    @include position(absolute, 0);
    height: 0; //
    &-container {
      width: 100%;
      @include aspectRatio(81.21212%);
    }
    svg {
      @include position(absolute, 0);
      width: auto;
      height: auto;
    }
  }

  &__scene {
    @include position(absolute, 0);
    width: 100%;

    &:before {
      @include position(absolute, 0);
      width: 100%;
      height: 100%;
      background-repeat: repeat;
      background: url("../../images/dither.png");
      background-size: 150px;
      z-index: 2;
    }

  }

  &__content {

    pointer-events: all;

    &-abs {
      @include position(absolute, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      margin: 0;
      width: 100%;
      max-width: none;
      pointer-events: none;
    }

    text-align: right;
    max-width: 600px;
    padding: 0;
    margin-top: -82px;
    //z-index: 2;
  }

  &__subheading,
  &__heading {
    text-align: center;
  }

  &__subheading {
    text-transform: uppercase;
    color: $cWhite;
    font-weight: $fwBold;
    letter-spacing: 2px;

  }

  &__heading {
    font-weight: 900;
    @include rhythm(100, 90);
    margin: 30px 0;
  }

  &__copy {
    margin: 0 0 0 20px;
    max-width: 360px;
    color: $cWhite;
    text-align: left;
    @include rhythm(19, 34);
    margin-top: 60px;
  }

  &__cta {
    display: inline-block;
    text-align: left;
  }
}

#welcome-path-gradient-circle {
  animation: wiggle 4s ease-in-out infinite;
  transform-origin: top left;
}

@keyframes wiggle {
  0% {
    transform: translate(-5px, -20px);
  }
  30% {
    transform: translate(5px, 20px);
  }
  100% {
    transform: translate(-5px, -20px);
  }
}